import React from "react";
import Navbar from "../component/Navbar";
import "../css/style.css";
import "../css/style.scss";
import "../css/responsive.css";
import Footer from "../component/Footer";

const About = () => {
  return (
    <>
      <div class="hero_area">
        <Navbar />

        <section class="about_section layout_padding">
          <div class="container  ">
            <div class="heading_container heading_center">
              <h2>
                About <span>Us</span>
              </h2>
            </div>
            <div class="row">
              <div class="col-md-6 ">
                <div class="img-box">
                  <img src="images/ab1.png" alt="" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="detail-box">
                  <h3>We Are G & 4D's Digital Concepts Limited</h3>
                  <p>
                  G&4D's Digital Concepts Limited is an Indigenous company that is officially incorporated with the Corporate Affairs Commission (CAC) to aid other businesses by providing personalized business support; we are proudly African, and our digital services are remarkable.
                  </p>
                  <p>
                  Our primary responsibility is to assist businesses in embracing the digital era, utilizing cutting-edge technologies, and streamlining operations to increase productivity and competitiveness in the marketplace; we facilitate the organic growth of other businesses throughout their digital lifecycles.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="about2_section layout_padding2" id="read_about2">
          <div class="container ">
            <div class="row">
              <div class="col-md-6">
                <div class="detail-box">
                  <p>
                    We leverage on our people, culture and experience - the
                    least we could do for your company is to use our resources
                    to directly position your (client) brands in front of their
                    audiences. We have complete faith in our team to provide
                    top-notch services. We are dedicated to providing our
                    clients with nothing less than great service at all times,
                    and always available to support. We employ a unique and
                    inventive approach, consistently enhancing every aspect of
                    our service offering.
                  </p>
                  <p>
                    We hear you when you talk, and we respond appropriately;
                    collaborating with clients to help them stay current in the
                    digital business space. Let us be your growth partner as we
                    assist other companies in starting their digital
                    transformation journeys or improving the current digital
                    framework within the organization.
                  </p>
                </div>
              </div>
              <div class="col-md-6 ">
                <div class="img-box">
                  <img src="images/ab2.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="mission_section layout_padding">
          <div class="service_container">
            <div class="container ">
              <div class="heading_container heading_center">
                <h2>
                  Our <span>Mission</span> & <span>Vision</span>
                </h2>
                <p>
                The basis of everything we do at G&4D's Digital Concepts Limited is our core values. Our actions and our dedication to our partners and clients are defined by these ideals.
                </p>
              </div>
              <div class="row">
                <div class="col-md-6 ">
                  <div class="box ">
                    <div class="img-box">
                      <img src="images/target.png" alt="Mission" />
                    </div>
                    <div class="detail-box">
                      <h5>Mission</h5>
                      <p>
                      We aspire to enable organizations to embrace and excel in the digital age by providing creative solutions that drive long-term change, increase efficiency, and generate long-term value for all stakeholders.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 ">
                  <div class="box ">
                    <div class="img-box">
                      <img src="images/vision.png" alt="Vision" />
                    </div>
                    <div class="detail-box">
                      <h5>Vision</h5>
                      <p>
                        <br />
                        We believe in collaboration and work closely with clients, partners, and stakeholders to co-create solutions and achieve shared success.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="service_section layout_padding">
          <div class="service_container">
            <div class="container ">
              <div class="heading_container heading_center">
                <h2>
                  Our Core <span>Values</span>
                </h2>
                <p>
                  At G & 4D's Digital Concepts Limited, our core values are the
                  foundation of everything we do. These values guide our actions
                  and define our commitment to our clients and partners.
                </p>
              </div>
              <div class="row">
                <div class="col-md-4 ">
                  <div class="box ">
                    <div class="img-box">
                      <img src="images/s1.png" alt="Innovation" />
                    </div>
                    <div class="detail-box">
                      <h5>Innovation</h5>
                      <p>
                      We value innovation and constant progress, creating cutting-edge concepts, tools, and methods to bring about revolutionary change.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 ">
                  <div class="box ">
                    <div class="img-box">
                      <img src="images/colaboration.png" alt="Collaboration" />
                    </div>
                    <div class="detail-box">
                      <h5>Collaboration</h5>
                      <p>
                      We collaborate closely with customers, partners, and stakeholders to co-create solutions and achieve shared success because we believe in the power of partnerships.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 ">
                  <div class="box ">
                    <div class="img-box">
                      <img src="images/integrity.png" alt="Integrity" />
                    </div>
                    <div class="detail-box">
                      <h5>Integrity</h5>
                      <p>
                      In all of our interactions and business activities, we embrace the highest ethical standards, creating openness, accountability, and trust.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 ">
                  <div class="box ">
                    <div class="img-box">
                      <img src="images/agile.png" alt="Agility" />
                    </div>
                    <div class="detail-box">
                      <h5>Agility</h5>
                      <p>
                        We thrive in dynamic environments, adapting quickly to
                        changing market conditions, customer needs, and
                        technological advancements to stay ahead of the curve.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 ">
                  <div class="box ">
                    <div class="img-box">
                      <img src="images/empowerment.png" alt="Empowerment" />
                    </div>
                    <div class="detail-box">
                      <h5>Empowerment</h5>
                      <p>
                        We empower individuals and organizations to unlock their
                        full potential, providing the tools, knowledge, and
                        support needed to succeed in the digital age.
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
                <div class="col-md-4 ">
                  <div class="box ">
                    <div class="img-box">
                      <img src="images/sustainable.png" alt="Sustainability" />
                    </div>
                    <div class="detail-box">
                      <h5>Sustainability</h5>
                      <p>
                        We are mindful of our impact on the environment and
                        society, promoting sustainable practices and solutions
                        that drive positive social, economic, and environmental
                        outcomes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default About;
