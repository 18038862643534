import React from "react";
import "../css/style.css";
import "../css/style2.css";
import "../css/style.scss";
import "../css/responsive.css";
import Footer from "../component/Footer";
import { Nav } from "react-bootstrap";

const Termofservice = () => {
  return (
    <>
      <div className="hero_area">
        <header class="header_section">
          <div class="container-fluid">
            <nav class="navbar navbar-expand-lg custom_nav-container ">
              <a class="navbar-brand" href="/">
                <img src="./images/g4d_logo2-removebg-preview.png" alt="G & 4D's Digital Concepts Limited"/>
              </a>

              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class=""> </span>
              </button>

              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav  ">
                <li class="nav-item">
                  <Nav.Link class="nav-link" href="/">
                    HOME
                  </Nav.Link>
                </li>
                <li class="nav-item">
                  <Nav.Link class="nav-link" href="/about">
                    About us
                  </Nav.Link>
                </li>
                <li class="nav-item">
                  <Nav.Link class="nav-link" href="/our-services">
                    services
                  </Nav.Link>
                </li>
                <li class="nav-item">
                  <Nav.Link class="nav-link" href="/why-us">
                    Why Us
                  </Nav.Link>
                </li>
                <li class="nav-item">
                  <Nav.Link class="nav-link" href="/contact-us">
                    Contact
                  </Nav.Link>
                </li>
              </ul>
              </div>
            </nav>
          </div>
        </header>

        <section class="policy_section layout_padding">
          <div class="container">
            <div class="heading_container heading_center">
              <h2>
                Our <span>Terms of Service</span>
              </h2>
              <p>
              By accessing or using the website and services of G&4D's Digital Concepts Limited ("Company," "we," "us," or "our"), you agree to comply with and be bound by these Terms of Service. If you do not agree to these terms, please do not use our website or services.
              </p>
            </div>
            <div class="policy_container">
              <div class="layout_padding2">
                <h3>Services</h3>
                <p>
                We provide digital transformation services, including but not limited to [digital strategy consulting, software development, cloud migration, and data analytics]. The specific details, deliverables, and pricing of our services will be outlined in separate agreements or statements of work.
                </p>
              </div>
              <div class="layout_padding-bottom">
                <h3>User Responsibilities</h3>
                <p>You agree to:</p>
                <ul>
                  <li>
                  Provide accurate and complete information when using our services.
                  </li>
                  <li>
                  Use our website and services only for lawful purposes
                  </li>
                  <li>
                  Not interfere with or disrupt the integrity or performance of our website or services.
                  </li>
                  <li>Respect the intellectual property rights of the Company and third parties</li>
                </ul>
              </div>
              <div class="layout_padding-bottom">
                <h3>Intellectual Property</h3>
                <p>
                All content on our website, including text, graphics, logos, and software, is the property of G&4D’s Digital Concepts Limited or its content suppliers and is protected by Nigerian and international copyright laws.
                </p>
              </div>
              <div class="layout_padding-bottom">
                <h3>Privacy</h3>
                <p>Your use of our website and services is also governed by our Privacy Policy, which is incorporated into these Terms of Service by reference.</p>
              </div>
              <div class="layout_padding-bottom">
                <h3>Limitation of Liability</h3>
                <p>
                To the fullest extent permitted by law, G&4D’s Digital Concepts Limited shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.
                </p>
              </div>
              <div class="layout_padding-bottom">
                <h3>Disclaimers</h3>
                <p>Our services are provided "as is" without any warranties, expressed or implied. We do not guarantee that our website will be error-free or uninterrupted.</p>
              </div>
              <div class="layout_padding-bottom">
                <h3>Termination</h3>
                <p>
                We reserve the right to terminate or suspend your access to our services at our sole discretion, without notice, for conduct that we believe violates these Terms of Service or is harmful to other users, us, or third parties, or for any other reason.
                </p>
              </div>
              <div class="layout_padding-bottom">
                <h3>Governing Law</h3>
                <p>
                These Terms of Service shall be governed by and construed by the laws of the Federal Republic of Nigeria.
                </p>
              </div>
              <div class="layout_padding-bottom">
                <h3>Change of Terms</h3>
                <p>
                We reserve the right to modify these Terms of Service at any time. We will notify users of any changes by updating the date at the top of this page.
                </p>
              </div>
              <div class="layout_padding-bottom">
                <h3>Indemnification</h3>
                <p>
                You agree to indemnify and hold harmless G&4D's Digital Concepts Limited, its officers, directors, employees, and agents, from and against any claims, actions, suits, or proceedings, as well as any losses, liabilities, damages, costs, and expenses arising out of your use of our website or services.
                </p>
              </div>
              <div class="layout_padding-bottom">
                <h3>Severability</h3>
                <p>If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that the Terms will otherwise remain in full force and effect and enforceable.</p>
              </div>
              <div class="layout_padding-bottom">
                <h3>Entire Agreement</h3>
                <p>These Terms of Service constitute the entire agreement between you and G&4D's Digital Concepts Limited regarding the use of our website and services.</p>
              </div>
              <div class="layout_padding-bottom">
                <h3>Contact Information</h3>
                <p>
                If you have any questions about these Terms of Service, please contact us at:
                </p>
                <ul>
                  <h6>G&4D's Digital Concepts Limited</h6>
                  <li><b>Email: </b>digitalconceptsltd01@gmail.com</li>
                  <li><b>Phone: </b>09132222267</li>
                </ul>
                <p>By using our website or services, you acknowledge that you have read and understood these Terms of Service and agree to be bound by them.</p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Termofservice