import React from 'react';
import "../css/style.css";
import "../css/style2.css";
import "../css/style.scss";
import "../css/responsive.css";
import Footer from "../component/Footer";
import { Nav } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="hero_area">
        <header class="header_section">
          <div class="container-fluid">
            <nav class="navbar navbar-expand-lg custom_nav-container ">
              <a class="navbar-brand" href="/">
                <img src="./images/g4d_logo2-removebg-preview.png" alt="G & 4D's Digital Concepts Limited"/>
              </a>

              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class=""> </span>
              </button>

              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav  ">
                <li class="nav-item">
                  <Nav.Link class="nav-link" href="/">
                    HOME
                  </Nav.Link>
                </li>
                <li class="nav-item">
                  <Nav.Link class="nav-link" href="/about">
                    About us
                  </Nav.Link>
                </li>
                <li class="nav-item">
                  <Nav.Link class="nav-link" href="/our-services">
                    services
                  </Nav.Link>
                </li>
                <li class="nav-item">
                  <Nav.Link class="nav-link" href="/why-us">
                    Why Us
                  </Nav.Link>
                </li>
                <li class="nav-item">
                  <Nav.Link class="nav-link" href="/contact-us">
                    Contact
                  </Nav.Link>
                </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>

        <section class="policy_section layout_padding">
          <div class="container">
            <div class="heading_container heading_center">
              <h2>
                Our <span>Privacy Policy</span>
              </h2>
              <p>
              G&4D's Digital Concepts Limited ("we," "us," or "our") is committed to protecting the privacy of our website visitors and clients. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services.
              </p>
            </div>
            <div class="policy_container">
              <div class="layout_padding2">
                <h3>Information We Collect</h3>
                <p>We may collect the following types of information:</p>
                <ul>
                  <li>
                    <b>Personal Information: </b> Name, email address, phone number, and other contact details you provide.
                  </li>
                  <li>
                    <b>Usage Data: </b>Information on how you use our website, including IP address, browser type, pages visited, and time spent on the site.
                  </li>
                  <li>
                    <b>Cookies: </b>We use cookies to enhance your browsing experience and analyze website traffic.
                  </li>
                </ul>
              </div>
              <div class="layout_padding-bottom">
                <h3>How We Use Your Information</h3>
                <p>We use the collected information for:</p>
                <ul>
                  <li>Providing and improving our services.</li>
                  <li>Communicating with you about our products and services.</li>
                  <li>Analyzing website usage to enhance user experience</li>
                  <li>Complying with legal obligations</li>
                </ul>
              </div>
              <div class="layout_padding-bottom">
                <h3>Information Sharing and Disclosure</h3>
                <p>We do not sell your personal information. We may share your information with:</p>
                <ul>
                  <li>Service providers who assist us in operating our website and business</li>
                  <li>Legal authorities when required by law or to protect our rights</li>
                </ul>
              </div>
              <div class="layout_padding-bottom">
                <h3>Data Security</h3>
                <p>
                We implement reasonable security measures to protect your information. However, no method of transmission over the Internet is 100% secure.
                </p>
              </div>
              <div class="layout_padding-bottom">
                <h3>Your Rights</h3>
                <p>You have the right to:</p>
                <ul>
                  <li>Access your personal information.</li>
                  <li>Correct inaccurate information.</li>
                  <li>Request deletion of your data.</li>
                  <li>Opt-out of marketing communications.</li>
                </ul>
              </div>
              <div class="layout_padding-bottom">
                <h3>Changes to This Policy</h3>
                <p>
                We may update this policy from time to time. We will notify you of any changes by posting the new policy on this page.
                </p>
              </div>
              <div class="layout_padding-bottom">
                <h3>Contact Us</h3>
                <p>
                If you have any questions about this Privacy Policy, please contact us at:
                </p>
                <ul>
                  <h6>G&4D's Digital Concepts Limited</h6>
                  <li><b>Email: </b>digitalconceptsltd01@gmail.com</li>
                  <li><b>Phone: </b>09132222267</li>
                </ul>
              </div>
              <div class="layout_padding-bottom">
                <h3>Compliance with Nigerian Data Protection Regulations</h3>
                <p>
                We comply with the Nigeria Data Protection Regulation (NDPR) and other applicable data protection laws in Nigeria.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy