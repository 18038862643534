import React from "react";

const WhySection = () => {
  return (
    <div>
      <section class="why_section layout_padding">
        <div class="container">
          <div class="heading_container heading_center">
            <h2>
              Why Choose <span>Us</span>
            </h2>
          </div>
          <div class="why_container">
            <div class="box">
              <div class="img-box">
                <img src="images/w1.png" alt="" />
              </div>
              <div class="detail-box">
                <h5>Expertise and Resources</h5>
                <p>
                  We bring specialized expertise and resources to the table,
                  offering valuable insights, tools, and support to help your
                  business thrive.
                </p>
              </div>
            </div>
            <div class="box">
              <div class="img-box">
                <img src="images/s1.png" alt="" />
              </div>
              <div class="detail-box">
                <h5>Innovation</h5>
                <p>
                Partnering with us gives you access to innovative ideas and technology that might help you obtain a competitive advantage in your business.
                </p>
              </div>
            </div>
            <div class="box">
              <div class="img-box">
                <img src="images/expertise.png" alt="" />
              </div>
              <div class="detail-box">
                <h5>Efficiency and Effectiveness</h5>
                <p>
                Our services are designed to streamline operations, enhance efficiency, and maximize production, allowing you to reach your business goals faster and more effectively.
                </p>
              </div>
            </div>
            <div class="box">
              <div class="img-box">
                <img src="images/networking.png" alt="" />
              </div>
              <div class="detail-box">
                <h5>Network and Connections</h5>
                <p>
                Our extensive network of partners, clients, and industry contacts can lead to new opportunities, collaborations, and partnerships for your company.
                </p>
              </div>
            </div>
            <div class="box">
              <div class="img-box">
                <img src="images/adaptation.png" alt="" />
              </div>
              <div class="detail-box">
                <h5>Adaptability</h5>
                <p>
                Adaptability is critical in today's ever-changing corporate world. As a business enabler, we can assist you in being nimble and successfully navigating market developments.
                </p>
              </div>
            </div>
            <div class="box">
              <div class="img-box">
                <img src="images/risk-management.png" alt="" />
              </div>
              <div class="detail-box">
                <h5>Risk Management</h5>
                <p>
                We offer risk assessment and mitigation services, assisting you in identifying possible dangers to your organization and developing methods to mitigate them.
                </p>
              </div>
            </div>
            <div class="box">
              <div class="img-box">
                <img src="images/bank.png" alt="" />
              </div>
              <div class="detail-box">
                <h5>Cost Savings</h5>
                <p>
                Our solutions are tailored to provide maximum value at competitive pricing, allowing you to save money without sacrificing quality or performance.
                </p>
              </div>
            </div>
            <div class="box">
              <div class="img-box">
                <img src="images/partnership.png" alt="" />
              </div>
              <div class="detail-box">
                <h5>Long-term Partnership</h5>
                <p>
                We are dedicated to developing long-term relationships with our clients, working closely with them to understand their needs, and evolving alongside them as their businesses grow and change.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhySection;
